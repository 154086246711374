<template>
  <div v-if="!route.meta.hidePopup" class="popup" :class="{ 'show-popup-content': show }">
    <div
      class="popup-header"
      :class="{ 'show-popup-content': show }"
      @click="togglePopup"
    >
      <i class="fa-light fa-user-headset popup-icon"></i>
      <span>Har du brug for hjælp?</span>
      <i
        class="fa-light fa-chevron-up popup-chevron-icon"
        :class="{ rotate: show }"
      ></i>
    </div>
    <div class="popup-content">
      <h5>Brug for rådgivning?</h5>
      <p>
        Ønsker du at skræddersy din forsikring, eller er du i tvivl om, hvilke
        dækninger der er relevante for dig?
      </p>
      <p><b>Book en tid og bliv ringet op når det passer dig.</b></p>
      <router-link to="/kontakt" class="button block-button margin-top-20">Kontakt mig</router-link>
    </div>
  </div>
</template>

<script>
export default {
  name: "Popup",
  computed: {
    /*
     * Show popup from vue store
     */
    show() {
      return this.$store.state.showPopup;
    },
    /*
     * Route info
     */
    route() {
      return this.$route;
    },
  },
  methods: {
    /*
     * Show popup from vue store
     */
    togglePopup() {
      this.$store.commit("togglePopup", !this.show);
    },
  },
};
</script>
