export default {
  data() {
    return {
      loading: {
        conditions: false,
        factScheme: false,
      },
    };
  },
  methods: {
    /*
     * Get conditions
     */
    getConditions(productId, productName, objectCode, altLink) {
      let newWindow;

      this.clog("conditions", productId, productName, objectCode, altLink);

      if (altLink) {
        newWindow = window.open();
        newWindow.location = altLink;

        return;
      }

      let self = this;

      // Set loading to true
      self.loading.conditions = true;

      // Create api string
      let apiString = `/api/document/conditions`;

      // Name of product
      let name = `Betingelser-${productName}`;

      // Open window on variable
      newWindow = window.open();

      self
        .axios({
          method: "post",
          data: {
            productCode: productId,
            documentName: name,
            objectCode: objectCode || productId,
          },
          url: apiString,
          headers: {
            "Content-Type": "application/json",
            "x-ws-key": process.env.VUE_APP_KEY,
            Authorization: `Bearer ${self.$store.state.token}`,
          },
        })
        .then(function (response) {
          // Set window to new location
          newWindow.location = process.env.VUE_APP_ROOT_URL + response.data.url;

          // Set loading to false
          self.loading.conditions = false;
        })
        .catch(function () {
          // Close tab if it fails
          newWindow.close();

          // Set loading to false
          self.loading.conditions = false;
        });
    },
    /*
     * Get faktasheet
     */
    // eslint-disable-next-line no-unused-vars
    getFactScheme(productId, productName) {
      let self = this;

      // Set loading to true
      self.loading.factScheme = true;

      // Create api string
      let apiString = `/api/document/factScheme`;

      // Name of product
      let name = `Faktaark-${productName}`;

      // Open window on variable
      var newWindow = window.open();

      self
        .axios({
          method: "post",
          url: apiString,
          data: {
            productCode: productId,
            documentName: name,
          },
          headers: {
            "Content-Type": "application/json",
            "x-ws-key": process.env.VUE_APP_KEY,
            Authorization: `Bearer ${self.$store.state.token}`,
          },
        })
        .then(function (response) {
          // Set window to new location
          newWindow.location = process.env.VUE_APP_ROOT_URL + response.data.url;

          // Set loading to false
          self.loading.factScheme = false;
        })
        .catch(function () {
          // Close tab if it fails
          newWindow.close();

          // Set loading to false
          self.loading.factScheme = false;
        });
    },
  },
};
