<template>
  <div class="product-footer">
    <div class="container">
      <h2 class="text-align-center margin-bottom-30">
        {{ altTitle ? altTitle : `Mere om vores ${productName.toLowerCase()}` }}
      </h2>
      <span class="product-footer-description"><slot></slot></span>
      <ul class="product-footer-info" v-if="showButtons">
        <li>
          <a :href="productLink" target="_blank">
            <i class="fa-light fa-circle-info fa-fw"></i>
            <span class="product-footer-info-text"
              >Læs mere på hjemmesiden</span
            >
          </a>
        </li>
        <li>
          <button
            type="button"
            v-on:click="getConditions(productId, productName, objectCode, conditionsLink)"
            target="_blank"
            :disabled="loading.conditions"
          >
            <i
              v-if="!loading.conditions"
              class="fa-light fa-file-lines fa-fw"
            ></i>
            <i v-else class="fa-light fa-spinner-third fa-fw icon-spin"></i>
            <span class="product-footer-info-text">Hent betingelser</span>
          </button>
        </li>
        <li>
          <button
            type="button"
            v-on:click="getFactScheme(productId, productName)"
            target="_blank"
            :disabled="loading.factScheme"
          >
            <i
              v-if="!loading.factScheme"
              class="fa-light fa-clipboard-check fa-fw"
            ></i>
            <i v-else class="fa-light fa-spinner-third fa-fw icon-spin"></i>
            <span class="product-footer-info-text">Hent faktablad</span>
          </button>
        </li>
        <li v-if="showContactButton">
          <button type="button" v-on:click="$emit('contactFromFooter')">
            <i class="fa-light fa-comment-question fa-fw"></i>
            <span class="product-footer-info-text">Få et tilbud</span>
          </button>
        </li>
      </ul>
    </div>
    <trustpilot-widget
      v-if="showTrustpilot"
      biz-id="48c16bbd000064000503381b"
      type="micro-star"
      style="margin-top: 50px"
    />
  </div>
</template>

<script>
import downloadDocuments from "@/mixins/downloadDocuments.js";
import TrustpilotWidget from "@/components/TrustpilotWidget";

export default {
  name: "productFooter",
  props: {
    productName: {
      type: String,
      default: "Ukendt",
    },
    productId: {
      type: String,
      default: "Ukendt",
    },
    objectCode: {
      type: String,
      default: null,
    },
    productLink: {
      type: String,
      default: "https://aros-forsikring.dk/forsikringer/",
    },
    conditionsLink: {
      type: String,
      default: null,
    },
    altTitle: {
      type: String,
      default: null,
    },
    showButtons: {
      type: Boolean,
      default: true,
    },
    showContactButton: {
      type: Boolean,
      default: true,
    },
    showTrustpilot: {
      type: Boolean,
      default: true,
    },
  },
  mixins: [downloadDocuments],
  components: {
    TrustpilotWidget
  },
};
</script>
