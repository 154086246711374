<template>
  <div v-if="modal" class="modal">
    <div class="modal-overlay" @click="closeModal()" />
    <div class="modal-box scrollbar" ref="modal">
      <button type="button" class="modal-close" @click="closeModal()" />
      <div class="modal-header">
        <h4>{{ modal.title }}</h4>
      </div>
      <div class="modal-content" v-html="text" />
    </div>
  </div>
</template>

<script>
import descriptions from "@/assets/json/descriptions.json";

export default {
  name: "modal",
  props: {
    modal: Object,
  },
  data() {
    return {
      descriptions: descriptions,
    };
  },
  computed: {
    text() {
      // Check if description is in encyclopedia
      let desc = this.descriptions[this.modal.description];

      // If found, return text, otherwise return raw text
      if (desc) {
        return desc;
      } else {
        return this.modal.description;
      }
    },
  },
  watch: {
    modal: {
      handler: function (value) {
        let self = this;
        if (value) {
          setTimeout(function () {
            self.$refs.modal.classList.add("show-modal");
          }, 10);
        }
      },
    },
  },
  methods: {
    closeModal() {
      let self = this;

      // Remove show modal
      self.$refs.modal.classList.remove("show-modal");

      // Set timeout of close modal function, so that the modal animation finishes before it's closed
      setTimeout(function () {
        self.$store.commit("closeModal");
      }, 400);
    },
  },
};
</script>
