import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    basket: [],
    coupon: null,
    token: null,
    loading: false,
    temporaryProduct: null,
    modal: null,
    advertisement: null,
    activeProducts: null,
    mobileNavigation: false,
    showPopup: false,
  },
  mutations: {
    /*
     * Add token
     */
    addToken(state, token) {
      state.token = token;
    },
    /*
     * Add product
     */
    addProduct(state, product) {
      // Object assign the product so that it copies
      let assignedProduct = Object.assign({}, product);

      // Assign new product to basket
      state.basket.push(assignedProduct);

      // Save basket to local storage
      localStorage.setItem("basket", JSON.stringify(state.basket));

      // Set expiry date
      let date = new Date();
      date.setDate(date.getDate() + 7);
      localStorage.setItem("expiration", date);
    },
    /*
     * Update product
     */
    updateProduct(state, product) {
      // Update product
      Object.assign(state.basket[product.key], product.value);

      // Save basket to local storage
      localStorage.setItem("basket", JSON.stringify(state.basket));

      // Set expiry date
      let date = new Date();
      date.setDate(date.getDate() + 7);
      localStorage.setItem("expiration", date);
    },
    /*
     * Update product
     */
    updateProductPrice(state, price) {
      // Update product
      if (price.key || price.object.toLowerCase() in state.basket) state.basket[price.key || price.object.toLowerCase()].price = price;
    },
    /*
     * Add basket to local storage
     */
    addBasketToLocalStorage(state) {
      // Save basket to local storage
      localStorage.setItem("basket", JSON.stringify(state.basket));

      // Set expiry date
      let date = new Date();
      date.setDate(date.getDate() + 7);
      localStorage.setItem("expiration", date);
    },
    /*
     * Add basket to local storage
     */
    emptyBasket(state) {
      // Remove all products from basket
      state.basket = new Array();

      // Clear temporary product
      state.temporaryProduct = null;

      // Clear coupon
      state.coupon = null;

      // Clear entire local storage
      localStorage.clear();

      // Clear entire session storage
      sessionStorage.clear();
    },
    /*
     * Remove product
     */
    removeProductFromIndex(state, index) {
      // Remove product from index
      state.basket.splice(index, 1);

      // Save basket to local storage
      localStorage.setItem("basket", JSON.stringify(state.basket));

      // Set expiry date
      let date = new Date();
      date.setDate(date.getDate() + 7);
      localStorage.setItem("expiration", date);
    },
    /*
     * Initialize local storage
     */
    // eslint-disable-next-line no-unused-vars
    initialiseLocalStorage(state) {
      // Get expiration from localstorage
      let expiration = localStorage.getItem("expiration");

      // If the expiration date is met, delete local storage
      if (expiration) {
        let today = new Date();
        expiration = new Date(expiration);
        if (today > expiration) {
          localStorage.clear();
        }
      }

      // Find basket from local storage
      let basketFromLocalStorage = localStorage.getItem("basket");

      // Find coupon from session storage
      let couponFromSessionStorage = sessionStorage.getItem("coupon");

      // Find coupon from session storage
      let temporaryProductFromSessionStorage = sessionStorage.getItem("temporaryProduct");

      // Find coupon from session storage
      let advertisementFromSessionStorage = sessionStorage.getItem("advertisement");

      // Find coupon from session storage
      let activeProductsFromSessionStorage = sessionStorage.getItem("activeProducts");

      // if basket is from storage add to basket
      if (basketFromLocalStorage) {
        state.basket = JSON.parse(basketFromLocalStorage);
      }

      // if basket is from storage add to basket
      if (couponFromSessionStorage) {
        state.coupon = couponFromSessionStorage;
      }

      // if temporary product is from storage add to basket
      if (temporaryProductFromSessionStorage) {
        state.temporaryProduct = JSON.parse(temporaryProductFromSessionStorage);
      }

      // if temporary product is from storage add to basket
      if (advertisementFromSessionStorage) {
        state.advertisement = JSON.parse(advertisementFromSessionStorage);
      }

      // if temporary product is from storage add to basket
      if (activeProductsFromSessionStorage) {
        state.activeProducts = JSON.parse(activeProductsFromSessionStorage);
      }
    },
    /*
     * Function for adding coupon
     */
    addCoupon(state, coupon) {
      state.coupon = coupon;

      // Save basket to local storage
      sessionStorage.setItem("coupon", coupon);

      // Set expiry date
      let date = new Date();
      date.setDate(date.getDate() + 7);
      localStorage.setItem("expiration", date);
    },
    /*
     * Function for toggling popup
     */
    togglePopup(state, value) {
      state.showPopup = value;
    },
    /*
     * Function for showing popup after 5 seconds
     */
    showPopup(state) {
      if (!sessionStorage.getItem("popupShown")) {
        setTimeout(() => {
          state.showPopup = true;
          sessionStorage.setItem("popupShown", true);
        }, 5000);
      }
    },
    /*
     * Function for removing coupon
     */
    removeCoupon(state) {
      // Remove coupon from state
      state.coupon = null;

      // Remove coupon from local storage
      sessionStorage.removeItem("coupon");

      // Set expiry date
      let date = new Date();
      date.setDate(date.getDate() + 7);
      localStorage.setItem("expiration", date);
    },
    /*
     * Set loading to true
     */
    loadingTrue(state) {
      state.loading = true;
    },
    /*
     * Set loading to false
     */
    loadingFalse(state) {
      Vue.nextTick(() => {
        state.loading = false;
      });
    },
    /*
     * Function for removing coupon
     */
    temporaryProduct(state, product) {
      state.temporaryProduct = product;

      // Save basket to local storage
      sessionStorage.setItem("temporaryProduct", JSON.stringify(product));
    },
    /*
     * Function for removing coupon
     */
    deleteTemporaryProduct(state) {
      state.temporaryProduct = null;

      // Remove from session storage
      sessionStorage.removeItem("temporaryProduct");
    },
    /*
     * Function for setting modal
     */
    setModal(state, modal) {
      state.modal = modal;
    },
    /*
     * Function for closing modal
     */
    closeModal(state) {
      state.modal = null;
    },
    /*
     * Function for setting modal
     */
    openNavigation(state) {
      state.mobileNavigation = true;
    },
    /*
     * Function for closing modal
     */
    closeNavigation(state) {
      state.mobileNavigation = false;
    },
    /*
     * Function for setting modal
     */
    setAdvertisement(state, advertisement) {
      state.advertisement = advertisement;

      // Save advertisement to session storage
      sessionStorage.setItem("advertisement", JSON.stringify(advertisement));
    },
    /*
     * Function for setting modal
     */
    removeAdvertisement(state) {
      state.advertisement = null;

      // Remove from session storage
      sessionStorage.removeItem("advertisement");
    },
    /*
     * Function for setting modal
     */
    setActiveProducts(state, activeProducts) {
      state.activeProducts = activeProducts;

      // Save advertisement to session storage
      sessionStorage.setItem("activeProducts", JSON.stringify(activeProducts));
    },
    /*
     * Function for setting modal
     */
    removeActiveProducts(state) {
      state.activeProducts = null;

      // Remove from session storage
      sessionStorage.removeItem("activeProducts");
    },
  },
  actions: {},
  modules: {},
});
