<template>
  <div v-if="mobileNavigation" class="mobile-navigation">
    <div class="mobile-navigation-overlay" @click="closeNavigation()" />
    <div class="mobile-navigation-box scrollbar" ref="mobileNavigation">
      <div class="mobile-navigation-header">
        <button
          type="button"
          class="navigation-close"
          @click="closeNavigation()"
        />
      </div>
      <nav class="mobile-navigation-menu">
        <h3>Menu</h3>
        <button class="mobile-navigation-link" @click="goToPage('Hjem')">
          <span>Alle produkter</span>
        </button>
        <!-- Run loop to show active products -->
        <button
          v-for="(product, index) in combinedActiveProducts"
          :key="index"
          class="mobile-navigation-link"
          @click="goToPage(product.productName)"
        >
          <span>{{ product.productTitle || product.productName }}</span>
        </button>
        <button class="mobile-navigation-link" @click="goToPage('Kurv')">
          <span>Indkøbskurv ({{ numberOfItems }})</span>
        </button>
        <button class="mobile-navigation-link" @click="goToPage('Henvendelse')">
          <span>Kontakt mig</span>
        </button>
      </nav>
      <div class="mobile-navigation-footer">
        <a href="https://www.aros-forsikring.dk" class="mobile-navigation-footer-link" target="_blank" @click="closeNavigation()">
          <i class="fa-light fa-house"></i>
          <span>Læs mere på hjemmesiden</span>
        </a>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "MobileNavigation",
  props: {
    mobileNavigation: Boolean,
  },
  computed: {
    numberOfItems() {
      return this.$store.state.basket.reduce((prev, val) => { return "extra" in val ? prev + 1 + Object.values(val.extra).reduce((prev, val) => { return val.chosen ? prev + 1 : prev; }, 0) : prev + 1; }, 0);
    },
  },
  watch: {
    mobileNavigation: {
      handler: function (value) {
        let self = this;
        if (value) {
          setTimeout(function () {
            self.$refs.mobileNavigation.classList.add("show-navigation");
          }, 10);
        }
      },
    },
  },
  methods: {
    goToPage(name) {
      // Go to page
      this.$router.push({ name: name });

      // Close navigation
      this.closeNavigation();
    },

    closeNavigation() {
      let self = this;

      // Remove show modal
      self.$refs.mobileNavigation.classList.remove("show-navigation");

      // Set timeout of close modal function, so that the modal animation finishes before it's closed
      setTimeout(function () {
        self.$store.commit("closeNavigation");
      }, 400);
    },
  },
};
</script>
