<template>
  <header>
    <div
      v-if="advertisement"
      class="top-advertisement"
      :style="advertisementColor"
    >
      <a
        class="top-advertisement-link"
        target="_blank"
        :href="advertisement.link"
        tabindex="-1"
        :class="{ clickableAd: advertisement.link }"
        >{{ advertisement.text }}</a
      >
    </div>
    <div class="top-navigation">
      <button
        class="mobile-navigation-button"
        @click="$store.commit('openNavigation')"
      />
      <router-link to="/" class="header-logo" tabindex="-1">
        <img
          src="@/assets/img/logo/logo-aflang-lilla.svg"
          alt="Aros Forsikring GS"
          class="header-logo-image"
        />
      </router-link>
      <nav>
        <router-link to="/" tabindex="-1">Alle produkter</router-link>
        <!-- <router-link :to="{ name: 'Bilforsikring' }" tabindex="-1">Bilforsikring</router-link> -->
      </nav>
      <div class="function-menu">
        <router-link
          to="/kontakt"
          class="function-item"
          tabindex="-1"
          id="contact-function-link"
        >
          <span class="link-text">Kontakt mig</span>
        </router-link>
        <router-link
          to="/kurv"
          class="function-item"
          ref="basket"
          tabindex="-1"
          id="basket-function-link"
        >
          <div class="basket">
            <span v-if="numberOfItems > 0" class="basket-number">
              {{ numberOfItems }}
            </span>
          </div>
          <span class="link-text">Indkøbskurv</span>
        </router-link>
      </div>
    </div>
  </header>
</template>

<script>
export default {
  name: "navigation",
  props: {
    advertisement: {
      type: Object,
      default: new Object(),
    },
    numberOfItems: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {};
  },
  computed: {
    advertisementColor() {
      if (this.advertisement.bannerColor) {
        return `background-color: ${this.advertisement.bannerColor};`;
      } else {
        return null;
      }
    },
  },
};
</script>
