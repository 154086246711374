<template>
  <div id="app"
    :class="[{ advertisement: advertisement }, { 'without-navigation': hideNavigation }, { 'with-modal': modal }]">
    <navigation v-if="!hideNavigation" :advertisement="advertisement" :numberOfItems="numberOfItems" />
    <mobile-navigation :mobileNavigation="mobileNavigation" />
    <div class="content" :class="[
      { 'with-advertisement': advertisement },
      { 'without-navigation': hideNavigation },
    ]">
      <router-view v-if="$store.state.token" v-show="!loading" />
    </div>
    <loader :loading="loading" />
    <modal :modal="modal" />

    <!-- Popup -->
    <Popup ref="popup" />
  </div>
</template>
<script>
import loader from "@/components/loader.vue";
import modal from "@/components/modal.vue";
import navigation from "@/components/navigation.vue";
import mobileNavigation from "@/components/mobile-navigation.vue";
import Popup from "@/components/Popup.vue";

export default {
  name: "App",
  data: function () {
    return {
      title: "Webshop",
    };
  },
  components: {
    loader,
    navigation,
    mobileNavigation,
    modal,
    Popup,
  },
  computed: {
    numberOfItems() {
      return this.$store.state.basket.reduce((prev, val) => { return "extra" in val ? prev + 1 + Object.values(val.extra).reduce((prev, val) => { return val.chosen ? prev + 1 : prev; }, 0) : prev + 1; }, 0);
    },
    loading() {
      return this.$store.state.loading;
    },
    modal() {
      return this.$store.state.modal;
    },
    advertisement() {
      return this.$store.state.advertisement;
    },
    hideNavigation() {
      return this.$route.meta.hideNavigation;
    },
    mobileNavigation() {
      return this.$store.state.mobileNavigation;
    },
  },
  created() {
    // Initialize local storage
    this.$store.commit("initialiseLocalStorage");
  },
  mounted() {
    //Get token and set interval for getting new token every hour and a half
    this.getToken(true);
    setInterval(this.getToken, 5400000);

    // Setup programmed cookie consents
    const self = this;

    window.addEventListener('CookiebotOnAccept', function (e) {
      if (Cookiebot.consent.marketing) {
        self.enableMarketingCookies();
      } else {
        self.disableMarketingCookies();
      }

    }, false);

    window.addEventListener('CookiebotOnDecline', function (e) {
      if (Cookiebot.consent.marketing) {
        self.enableMarketingCookies();
      } else {
        self.disableMarketingCookies();
      }

    }, false);
  },
  methods: {
    enableMarketingCookies() {
      this.$gtag.optIn();
      this.$gtm.enable(true);
    },
    disableMarketingCookies() {
      this.$gtag.optOut();
      this.$gtm.enable(false);
    },
    /*
     * Function for getting new token and setting it to vue store
     */
    getToken(loader) {
      let self = this;

      // Set loading to true
      if (loader) {
        self.$store.commit("loadingTrue");
      }

      self.axios
        .get("/api/token", {
          params: {
            preventCache: self.dayjs().unix(),
          },
          headers: {
            "x-ws-key": process.env.VUE_APP_KEY,
          },
        })
        .then((response) => {
          self.$store.commit("addToken", response.data.accessToken);

          // Set loading to false
          self.$store.commit("loadingFalse");

          // Check if application is alive
          self.isAlive();

          // Get active products
          if (!self.$store.state.activeProducts) {
            self.getActiveProducts();
          }

          // Get advertisement banner
          if (!self.advertisement) {
            self.getAdvertisement();
          }
        })
        .catch(() => {
          // Set loading to false
          self.$store.commit("loadingFalse");
        });
    },
    /*
     * Get advertisement from api
     */
    getAdvertisement() {
      let self = this;

      // Create api string
      let apiString = `/api/vouchers/advertisement`;

      self
        .axios({
          method: "get",
          url: apiString,
          params: {
            preventCache: self.dayjs().unix(),
          },
          headers: {
            "Content-Type": "application/json",
            "x-ws-key": process.env.VUE_APP_KEY,
            Authorization: `Bearer ${self.$store.state.token}`,
          },
        })
        .then((response) => {
          if (response.data) {
            self.$store.commit("setAdvertisement", response.data);
          }
        })
        .catch(() => {
          self.$store.commit("removeAdvertisement");
        });
    },
    /*
     * Check if application is alive
     */
    isAlive() {
      let self = this;

      // Create api string
      let apiString = "/api/isAlive";

      self
        .axios({
          method: "get",
          params: {
            preventCache: self.dayjs().unix(),
          },
          url: apiString,
          headers: {
            "Content-Type": "application/json",
            "x-ws-key": process.env.VUE_APP_KEY,
            Authorization: `Bearer ${self.$store.state.token}`,
          },
        })
        .then(function (response) {
          // If application is not alive push to lunkket
          if (!response.data) {
            self.$router.push({
              name: "Lukket",
              params: {
                type: "error",
              },
            });
          }
        })
        .catch(function () {
          self.$router.push({
            name: "Lukket",
            params: {
              type: "error",
            },
          });
        });
    },
    /*
     * Check if application is alive
     */
    getActiveProducts() {
      let self = this;

      // Create api string
      let apiString = `/api/activeProducts`;

      self.$store.commit("loadingTrue");

      self
        .axios({
          method: "get",
          url: apiString,
          params: {
            preventCache: self.dayjs().unix(),
          },
          headers: {
            "Content-Type": "application/json",
            "x-ws-key": process.env.VUE_APP_KEY,
            Authorization: `Bearer ${self.$store.state.token}`,
          },
        })
        .then(function (response) {
          self.$store.commit("setActiveProducts", response.data);

          // Set loading to false
          self.$store.commit("loadingFalse");
        })
        .catch(function () {
          // Set loading to false
          self.$store.commit("loadingFalse");
        });
    },
  },
};

</script>