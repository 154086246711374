import dayjs from "dayjs";
import axios from "axios";
import store from "../store/index";

// Setup defaults
const a = axios.create({
  baseURL: process.env.VUE_APP_ROOT_URL,
});

// Add interceptors
a.interceptors.request.use((request) => {
  // Add anti cache measure
  request.params = {
    ...{
      preventCache: dayjs().unix(),
    },
    ...request.params,
  };

  // Set default headers
  request.headers.common = {
    ...{
      "x-ws-key": process.env.VUE_APP_KEY,
    },
    ...request.headers.common,
  };

  // Add auth token
  if (store.state.token)
    request.headers.common.Authorization = `Bearer ${store.state.token}`;

  return request;
});

// Helpers
const handle = (res, resolve) => {
  resolve(res.data);
};

const crt = (url, data = null, params = {}) => {
  return new Promise((resolve) => {
    a.post(url, data, {
      params: params,
    }).then((res) => handle(res, resolve));
  });
};

const rdd = (url, params = {}) => {
  return new Promise((resolve, reject) => {
    a.get(url, {
      params: params,
    })
      .then((res) => handle(res, resolve))
      .catch((error) => {
        reject(error);
      });
  });
};

const upd = (url, data = null, params = {}) => {
  return new Promise((resolve) => {
    a.put(url, data, {
      params: params,
    }).then((res) => handle(res, resolve));
  });
};

const del = (url, params = {}) => {
  return new Promise((resolve) => {
    a.delete(url, {
      params: params,
    }).then((res) => handle(res, resolve));
  });
};

// API
export default {
  examples: {
    create: (data) => crt(`/api/examples`, data),
    read: (id) => rdd(`/api/examples/${id}`),
    update: (id, data) => upd(`/api/examples/${id}`, data),
    delete: (id) => del(`/api/examples/${id}`),
  },
  vouchers: {
    any: {
      read: () => rdd("/api/vouchers/any"),
    },
  },
  building: {
    information: {
      read: (address) => rdd(`/api/building/information/P28/P28?address=${address}`)
    },
    informationCottage: {
      read: (address) => rdd(`/api/building/information/P23/P23?address=${address}`)
    },
  },
  dog: {
    excesses: {
      read: () => rdd('/api/excesses/P53/P53?bundleCode=720')
    },
  },
};
