<template>
  <div ref="widget">
    <!-- TrustBox widget - Micro Button -->
    <div
      v-if="type == 'micro-button'"
      class="trustpilot-widget"
      :data-locale="locale"
      data-template-id="5419b757fa0340045cd0c938"
      :data-businessunit-id="bizId"
      data-style-height="25px"
      data-style-width="100%"
    >
      <a
        href="https://dk.trustpilot.com/review/www.aros-forsikring.dk"
        target="_blank"
        rel="noopener"
        >Trustpilot</a
      >
    </div>
    <!-- TrustBox widget - Micro Combo -->
    <div
      v-if="type == 'micro-combo'"
      class="trustpilot-widget"
      data-locale="da-DK"
      data-template-id="5419b6ffb0d04a076446a9af"
      :data-businessunit-id="bizId"
      data-style-height="20px"
      data-style-width="100%"
      data-theme="light"
    >
      <a
        href="https://dk.trustpilot.com/review/www.aros-forsikring.dk"
        target="_blank"
        rel="noopener"
        >Trustpilot</a
      >
    </div>
    <!-- TrustBox widget - Micro Review Count -->
    <div
      v-if="type == 'micro-review-count'"
      class="trustpilot-widget"
      data-locale="da-DK"
      data-template-id="5419b6a8b0d04a076446a9ad"
      :data-businessunit-id="bizId"
      data-style-height="24px"
      data-style-width="100%"
      data-theme="light"
    >
      <a
        href="https://dk.trustpilot.com/review/www.aros-forsikring.dk"
        target="_blank"
        rel="noopener"
        >Trustpilot</a
      >
    </div>
    <!-- TrustBox widget - Micro Star -->
    <div
      v-if="type == 'micro-star'"
      class="trustpilot-widget"
      data-locale="da-DK"
      data-template-id="5419b732fbfb950b10de65e5"
      :data-businessunit-id="bizId"
      data-style-height="24px"
      data-style-width="100%"
      data-theme="light"
    >
      <a
        href="https://dk.trustpilot.com/review/www.aros-forsikring.dk"
        target="_blank"
        rel="noopener"
        >Trustpilot</a
      >
    </div>
    <!-- TrustBox widget - Micro TrustScore -->
    <div
      v-if="type == 'micro-trustscore'"
      class="trustpilot-widget"
      data-locale="da-DK"
      data-template-id="5419b637fa0340045cd0c936"
      :data-businessunit-id="bizId"
      data-style-height="20px"
      data-style-width="100%"
      data-theme="light"
    >
      <a
        href="https://dk.trustpilot.com/review/www.aros-forsikring.dk"
        target="_blank"
        rel="noopener"
        >Trustpilot</a
      >
    </div>
    <!-- TrustBox widget - Mini -->
    <div
      v-if="type == 'mini'"
      class="trustpilot-widget"
      data-locale="da-DK"
      data-template-id="53aa8807dec7e10d38f59f32"
      :data-businessunit-id="bizId"
      data-style-height="150px"
      data-style-width="100%"
      data-theme="light"
    >
      <a
        href="https://dk.trustpilot.com/review/www.aros-forsikring.dk"
        target="_blank"
        rel="noopener"
        >Trustpilot</a
      >
    </div>
    <!-- TrustBox widget - Starter -->
    <div
      v-if="type == 'starter'"
      class="trustpilot-widget"
      data-locale="da-DK"
      data-template-id="5613c9cde69ddc09340c6beb"
      :data-businessunit-id="bizId"
      data-style-height="100%"
      data-style-width="100%"
      data-theme="light"
    >
      <a
        href="https://dk.trustpilot.com/review/www.aros-forsikring.dk"
        target="_blank"
        rel="noopener"
        >Trustpilot</a
      >
    </div>
    <!-- TrustBox widget - Carousel -->
    <div
      v-if="type == 'carousel'"
      class="trustpilot-widget"
      data-locale="da-DK"
      data-template-id="53aa8912dec7e10d38f59f36"
      :data-businessunit-id="bizId"
      data-style-height="140px"
      data-style-width="100%"
      data-theme="light"
      data-stars="1,2,3,4,5"
      data-review-languages="da"
    >
      <a
        href="https://dk.trustpilot.com/review/www.aros-forsikring.dk"
        target="_blank"
        rel="noopener"
        >Trustpilot</a
      >
    </div>
    <!-- TrustBox widget - Drop-Down -->
    <div
      v-if="type == 'drop-down'"
      class="trustpilot-widget"
      data-locale="da-DK"
      data-template-id="5418052cfbfb950d88702476"
      :data-businessunit-id="bizId"
      data-style-height="30px"
      data-style-width="100%"
      data-theme="light"
      data-stars="1,2,3,4,5"
      data-review-languages="da"
    >
      <a
        href="https://dk.trustpilot.com/review/www.aros-forsikring.dk"
        target="_blank"
        rel="noopener"
        >Trustpilot</a
      >
    </div>
    <!-- TrustBox widget - Grid -->
    <div
      v-if="type == 'grid'"
      class="trustpilot-widget"
      data-locale="da-DK"
      data-template-id="539adbd6dec7e10e686debee"
      :data-businessunit-id="bizId"
      data-style-height="500px"
      data-style-width="100%"
      data-theme="light"
      data-stars="1,2,3,4,5"
      data-review-languages="da"
    >
      <a
        href="https://dk.trustpilot.com/review/www.aros-forsikring.dk"
        target="_blank"
        rel="noopener"
        >Trustpilot</a
      >
    </div>
    <!-- TrustBox widget - List -->
    <div
      v-if="type == 'list'"
      class="trustpilot-widget"
      data-locale="da-DK"
      data-template-id="539ad60defb9600b94d7df2c"
      :data-businessunit-id="bizId"
      data-style-height="500px"
      data-style-width="100%"
      data-theme="light"
      data-stars="1,2,3,4,5"
      data-review-languages="da"
    >
      <a
        href="https://dk.trustpilot.com/review/www.aros-forsikring.dk"
        target="_blank"
        rel="noopener"
        >Trustpilot</a
      >
    </div>
    <!-- TrustBox widget - List Filtered -->
    <div
      v-if="type == 'list-filtered'"
      class="trustpilot-widget"
      data-locale="da-DK"
      data-template-id="539ad998dec7e10e686debe0"
      :data-businessunit-id="bizId"
      data-style-height="500px"
      data-style-width="100%"
      data-theme="light"
      data-review-languages="da"
    >
      <a
        href="https://dk.trustpilot.com/review/www.aros-forsikring.dk"
        target="_blank"
        rel="noopener"
        >Trustpilot</a
      >
    </div>
    <!-- TrustBox widget - Mini Carousel -->
    <div
      v-if="type == 'mini-carousel'"
      class="trustpilot-widget"
      data-locale="da-DK"
      data-template-id="539ad0ffdec7e10e686debd7"
      :data-businessunit-id="bizId"
      data-style-height="350px"
      data-style-width="100%"
      data-theme="light"
      data-stars="1,2,3,4,5"
      data-review-languages="da"
    >
      <a
        href="https://dk.trustpilot.com/review/www.aros-forsikring.dk"
        target="_blank"
        rel="noopener"
        >Trustpilot</a
      >
    </div>
    <!-- TrustBox widget - Pop-Up -->
    <div
      v-if="type == 'pop-up'"
      class="trustpilot-widget"
      data-locale="da-DK"
      data-template-id="5418015fb0d04a0c9cf721f2"
      :data-businessunit-id="bizId"
      data-style-height="30px"
      data-style-width="100%"
      data-theme="light"
      data-stars="1,2,3,4,5"
      data-review-languages="da"
    >
      <a
        href="https://dk.trustpilot.com/review/www.aros-forsikring.dk"
        target="_blank"
        rel="noopener"
        >Trustpilot</a
      >
    </div>
    <!-- TrustBox widget - Quote -->
    <div
      v-if="type == 'quote'"
      class="trustpilot-widget"
      data-locale="da-DK"
      data-template-id="54d0e1d8764ea9078c79e6ee"
      :data-businessunit-id="bizId"
      data-style-height="300px"
      data-style-width="100%"
      data-theme="light"
      data-stars="1,2,3,4,5"
      data-review-languages="da"
    >
      <a
        href="https://dk.trustpilot.com/review/www.aros-forsikring.dk"
        target="_blank"
        rel="noopener"
        >Trustpilot</a
      >
    </div>
    <!-- TrustBox widget - Slider -->
    <div
      v-if="type == 'slider'"
      class="trustpilot-widget"
      data-locale="da-DK"
      data-template-id="54ad5defc6454f065c28af8b"
      :data-businessunit-id="bizId"
      data-style-height="240px"
      data-style-width="100%"
      data-theme="light"
      data-stars="1,2,3,4,5"
      data-review-languages="da"
    >
      <a
        href="https://dk.trustpilot.com/review/www.aros-forsikring.dk"
        target="_blank"
        rel="noopener"
        >Trustpilot</a
      >
    </div>
  </div>
</template>

<script>
export default {
  props: {
    bizId: {
      type: String,
      required: true,
    },
    locale: {
      type: String,
      default: "da-DK",
    },
    type: {
      type: String,
      required: true,
      validator: (val) => {
        return [
          "micro-button",
          "micro-combo",
          "micro-review-count",
          "micro-star",
          "micro-trustscore",
          "mini",
          "starter",
          "carousel",
          "drop-down",
          "grid",
          "list",
          "list-filtered",
          "mini-carousel",
          "pop-up",
          "quote",
          "slider",
        ].includes(val);
      },
    },
  },
  mounted() {
    const elements =
      this.$refs.widget.getElementsByClassName("trustpilot-widget");

    for (const el of elements) {
      if (window.Trustpilot && el) window.Trustpilot.loadFromElement(el);
    }
  },
};
</script>
