<template>
  <div>
    <div class="container">
      <h1 class="page-headline">Produkter</h1>
      <div class="row">
        <template v-if="combinedActiveProducts">
          <div
            class="column four"
            v-for="(product, index) in combinedActiveProducts"
            :key="index"
          >
            <div class="product-card" tabindex="-1">
              <span v-if="product.hasBanner" class="sticker">{{
                product.bannerText
              }}</span>
              <div class="product-card-content">
                <img
                  class="product-card-icon"
                  :src="
                    require(`@/assets/img/products/${product.productIcon}.svg`)
                  "
                />
                <div class="product-card-description">
                  <span class="product-card-header">{{
                    product.productTitle || product.productName
                  }}</span>
                  <span class="product-card-manchet">{{
                    product.productIntroText
                  }}</span>
                </div>
              </div>
              <div
                class="button-flex-container no-padding product-card-buttons"
              >
                <a
                  :href="product.productLink"
                  target="_blank"
                  class="button white"
                  >Læs mere</a
                >
                <router-link :to="product.productPath" class="button flex-grow"
                  >Beregn pris</router-link
                >
              </div>
            </div>
          </div>
        </template>
      </div>
    </div>
    <!-- Product footer -->
    <product-footer
      class="full-width"
      altTitle="Vores kunder siger"
      :showButtons="false"
      :showTrustpilot="false"
    >
      <trustpilot-widget biz-id="48c16bbd000064000503381b" type="carousel" style="margin-top: 50px;" />
    </product-footer>
  </div>
</template>

<script>
import ProductFooter from "@/components/product-footer.vue";
import TrustpilotWidget from "@/components/TrustpilotWidget.vue";

export default {
  name: "Hjem",
  components: {
    ProductFooter,
    TrustpilotWidget,
  },
  mounted(){
    console.log("application started....")
  }
};
</script>
