import Vue from "vue";
import VueRouter from "vue-router";
import Hjem from "../views/Hjem.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Hjem",
    component: Hjem,
    meta: {
      title: "Alle produkter",
      description: "Beskrivelse af siden på webshoppen",
    },
  },
  {
    path: "/bilforsikring",
    name: "Bilforsikring",
    component: () => import("../views/M14.vue"),
    props: true,
    meta: {
      title: "Bilforsikring",
      description: "Beskrivelse af siden på webshoppen",
    },
  },
  {
    path: "/elbilforsikring",
    name: "Elbil Plus",
    component: () => import("../views/M92.vue"),
    props: true,
    meta: {
      title: "Elbil Plus",
      description: "Beskrivelse af siden på webshoppen",
    },
  },
  {
    path: "/bmc-leasing",
    name: "BmcLeasing",
    component: () => import("../views/M90.vue"),
    props: true,
    meta: {
      title: "BMC Leasing",
      description: "Beskrivelse af siden på webshoppen",
      hideNavigation: true,
      hidePopup: true,
    },
  },
  {
    path: "/forsikring-opdateret",
    name: "BmcLeasingEnd",
    component: () => import("../views/M90End.vue"),
    props: true,
    meta: {
      title: "Din forsikring er opdateret",
      description: "Beskrivelse af siden på webshoppen",
      hideNavigation: true,
      hidePopup: true,
    },
  },
  {
    path: "/fejl",
    name: "Error",
    component: () => import("../views/Error.vue"),
    props: true,
    meta: {
      title: "Fejl",
      description: "Beskrivelse af siden på webshoppen",
      hideNavigation: true,
      hidePopup: true,
    },
  },
  {
    path: "/veteranbilforsikring",
    name: "Veteranbilforsikring",
    component: () => import("../views/M21.vue"),
    props: true,
    meta: {
      title: "Veteranbilforsikring",
      description: "Beskrivelse af siden på webshoppen",
    },
  },
  {
    path: "/campingvognsforsikring",
    name: "Campingvognsforsikring",
    component: () => import("../views/M71.vue"),
    props: true,
    meta: {
      title: "Campingvognsforsikring",
      description: "Beskrivelse af siden på webshoppen",
    },
  },
  {
    path: "/indbo-og-aarsrejseforsikring",
    name: "Indbo- og årsrejseforsikring",
    component: () => import("../views/P08.vue"),
    props: true,
    meta: {
      title: "Indbo- og årsrejseforsikring",
      description: "Beskrivelse af siden på webshoppen",
    },
  },
  {
    path: "/indboforsikring",
    name: "Indboforsikring",
    component: () => import("../views/P11.vue"),
    props: true,
    meta: {
      title: "Indboforsikring",
      description: "Beskrivelse af siden på webshoppen",
    },
  },
  {
    path: "/husforsikring",
    name: "Husforsikring",
    component: () => import("../views/P28.vue"),
    props: true,
    meta: {
      title: "Husforsikring",
      description: "Beskrivelse af siden på webshoppen",
    },
  },
  {
    path: "/sommerhusforsikring",
    name: "Sommerhusforsikring",
    component: () => import("../views/P23.vue"),
    props: true,
    meta: {
      title: "Sommerhusforsikring",
      description: "Beskrivelse af siden på webshoppen",
    },
  },
  {
    path: "/ulykkesforsikring-voksen",
    name: "Ulykkesforsikring - Voksen",
    component: () => import("../views/U06_Adult.vue"),
    props: true,
    meta: {
      title: "Ulykkesforsikring - Voksen",
      description: "Beskrivelse af siden på webshoppen",
    },
  },
  {
    path: "/ulykkesforsikring-barn",
    name: "Ulykkesforsikring - Barn",
    component: () => import("../views/U06_Child.vue"),
    props: true,
    meta: {
      title: "Ulykkesforsikring - Barn",
      description: "Beskrivelse af siden på webshoppen",
    },
  },
  {
    path: "/hundeforsikring",
    name: "Hundeforsikring",
    component: () => import("../views/P53.vue"),
    props: true,
    meta: {
      title: "Hundeforsikring",
      description: "Beskrivelse af siden på webshoppen",
    },
  },
  {
    path: "/rejseforsikring",
    name: "Rejseforsikring",
    component: () => import("../views/P60.vue"),
    props: true,
    meta: {
      title: "Rejseforsikring",
      description: "Beskrivelse af siden på webshoppen",
    },
  },
  {
    path: "/kurv",
    name: "Kurv",
    component: () => import("../views/Kurv.vue"),
    meta: {
      title: "Indkøbskurv",
      description: "Beskrivelse af siden på webshoppen",
    },
  },
  {
    path: "/betaling",
    name: "Betaling",
    component: () => import("../views/Betaling.vue"),
    meta: {
      title: "Kassen",
      description: "Beskrivelse af siden på webshoppen",
    },
  },
  {
    path: "/kontakt",
    name: "Henvendelse",
    component: () => import("../views/Henvendelse.vue"),
    props: true,
    meta: {
      title: "Kontakt mig",
      description: "Beskrivelse af siden på webshoppen",
      hidePopup: true,
    },
  },
  {
    path: "/ordre",
    name: "Ordre",
    component: () => import("../views/Ordre.vue"),
    props: true,
    meta: {
      title: "Ordre",
      description: "Beskrivelse af siden på webshoppen",
      hideNavigation: false,
    },
  },
  {
    path: "/lukket",
    name: "Lukket",
    component: () => import("../views/Lukket.vue"),
    props: true,
    meta: {
      title: "Lukket",
      description: "Beskrivelse af siden på webshoppen",
      hideNavigation: true,
    },
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

// This callback runs before every route change, including on page load.
router.beforeEach((to, from, next) => {
  // Current time
  let hours = new Date().getHours();

  // If between one and six, redirect to closed (only for production)
  if (process.env.NODE_ENV == 'production' && to.name != "Lukket" && hours > 1 && hours < 6) {
    router.push({
      name: "Lukket",
      params: {
        type: "closed",
      },
    });
  }

  // If a route with a title was found, set the document (page) title to that value.
  if (to.meta.title) {
    document.title = to.meta.title + " - Aros Forsikring Webshop";
  } else {
    document.title = "Aros Forsikring Webshop";
  }

  // If a route with a description was found, set the document (page) description to that value.
  if (to.meta.description) {
    document
      .querySelector('meta[name="description"]')
      .setAttribute("content", to.meta.description);
  } else {
    document.description = "Aros Forsikring Webshop";
  }

  next();
});

// eslint-disable-next-line no-unused-vars
router.afterEach((to, from) => {
  // Scroll into view
  //document.getElementById("app").scrollIntoView();
  window.scrollTo(0, 0);
});

export default router;
