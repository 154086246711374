<template>
  <div v-show="loading" class="loader">
    <img src="@/assets/img/logo/logo-hjerte-lilla.svg" class="loader-image" />
    <div class="spinner"></div>
  </div>
</template>

<script>
export default {
  name: "loader",
  props: {
    loading: Boolean,
  },
};
</script>
