import Vue from "vue";
import App from "./App.vue";
import "./registerServiceWorker";
import router from "./router";
import store from "./store";
import "normalize.css";
import "./assets/css/main.scss";
import VueCryptojs from "vue-cryptojs";
import axios from "axios";
import dayjs from "dayjs";
import VueGtag from "vue-gtag";
import VShowSlide from "v-show-slide";
import VueGtm from "@gtm-support/vue2-gtm";
import VueFacebookPixel from "vue-facebook-pixel";
import api from './services/api';

Vue.config.productionTip = false;

// Global Google Analytics
Vue.use(
  VueGtag,
  {
    config: { id: process.env.VUE_APP_GTAG_ID },
    enabled: false
  },
  router
);

// Google tag manager
Vue.use(VueGtm, {
  enabled: false,
  id: "GTM-MSZWVFN",
  queryParams: {
    gtm_auth: process.env.VUE_APP_GTM_AUTH,
    gtm_preview: process.env.VUE_APP_GTM_PREVIEW,
    gtm_cookies_win: "x",
  },
  debug:
    process.env.NODE_ENV == "development" ||
    process.env.NODE_ENV == "test" ||
    process.env.NODE_ENV == "beta"
      ? true
      : false,
  loadScript: true,
  vueRouter: router,
});

// Vue Facebook pixel
Vue.use(VueFacebookPixel);

// CryptoJS for vue for encryptin data
Vue.use(VueCryptojs);

// Show slide plugin
Vue.use(VShowSlide);

// Axios for communicating with backend
Vue.prototype.axios = axios;
axios.defaults.baseURL = process.env.VUE_APP_ROOT_URL;

// API
Vue.prototype.$api = api;

/**
 * Everything about Day.JS
 */
// Day.js plugins
require("dayjs/locale/da");
var updateLocale = require("dayjs/plugin/updateLocale");
var calendar = require("dayjs/plugin/calendar");
var isoWeek = require("dayjs/plugin/isoWeek");
var isBetween = require("dayjs/plugin/isBetween");

// Extend with plugins
dayjs.extend(updateLocale);
dayjs.extend(calendar);
dayjs.extend(isoWeek);
dayjs.extend(isBetween);

dayjs.locale("da");
dayjs.updateLocale("da", {
  calendar: {
    lastDay: "[I går]",
    sameDay: "[I dag]",
    nextDay: "[I morgen]",
    lastWeek: "dddd",
    nextWeek: "dddd",
    sameElse: "dddd",
  },
  weekStart: 0,
});
Vue.prototype.dayjs = dayjs;

/**
     * Format numbers into money
     */
const numFormat = (n, c, d, t) => {
  c = isNaN((c = Math.abs(c))) ? 2 : c;
  d = d == undefined ? "," : d;
  t = t == undefined ? "." : t;

  // Vars
  let s = n < 0 ? "-" : "",
    i = String(parseInt((n = Math.abs(Number(n) || 0).toFixed(c)))),
    j = i.length > 3 ? i.length % 3 : 0;

  return (
    s +
    (j ? i.substr(0, j) + t : "") +
    i.substr(j).replace(/(\d{3})(?=\d)/g, "$1" + t) +
    (c
      ? d +
        Math.abs(n - i)
          .toFixed(c)
          .slice(2)
      : "")
  );
};

Vue.filter("money", (val) => {
  return numFormat(val);
});

Vue.filter("moneyShortSuffixed", (val) => {
  return numFormat(val, 0) + ' kr.';
});

Vue.filter("moneyShort", (val) => {
  return numFormat(val, 0);
});

const renderText = (string, data) => {
  return string.replace(/{{\s*([\w.]+)\s*}}/g, (match, capt) => {
    const p = capt.split('.');
    let a = data[p[0]];

    for (let i = 1; i < p.length; ++i) {
      a = a[p[i]];
    }

    return a;
  });
};

Vue.filter("renderText", renderText);

const renderTextInObject_handleItem = (item, data) => {
  if (Array.isArray(item)) {
    // Array
    let r = [];

    for (const value of item) {
      r.push(renderTextInObject_handleItem(value, data));
    }

    return r;
  } else if (typeof item == 'string') {
    // String
    return renderText(item, data);
  } else if (typeof item == 'object') {
    // Object
    let r = {};

    for (const [key, value] of Object.entries(item)) {
      r[key] = renderTextInObject_handleItem(value, data);
    }

    return r;
  } else {
    // Other
    return item;
  }
};

const renderTextInObject = (object, data) => {
  return renderTextInObject_handleItem(object, data);
};

Vue.filter("renderTextInObject", renderTextInObject);

// Abbreviations
const abbr = [
  [/nuv./g, "nuværende"]
];

Vue.filter("expand", (string) => {
  let str = string;

  // Replace abbr
  for (const set of abbr) {
    str = str.replace(set[0], set[1]);
  }

  return str;
});

Vue.mixin({
  computed: {
    activeProducts() {
      if (
        process.env.NODE_ENV == "development-off"
      ) {
        return [
          {"productCode":"P08","productOrder":2,"hasBanner":false},
          {"productCode":"M21","productOrder":3,"hasBanner":false},
          {"productCode":"M14","productOrder":1,"bannerText":"Der er lige nu 20% rabat på Bilforsikring, brug rabatkoden: Rabat20","hasBanner":true},
          {"productCode":"U06","productOrder":4,"hasBanner":false},
          {"productCode":"P28","productOrder":5,"hasBanner":false},
          {"productCode":"M71","productOrder":5,"hasBanner":false},
          {"productCode":"M92","productOrder":5,"hasBanner":false},
          {"productCode":"P53","productOrder":5,"hasBanner":false},
          {"productCode":"P60","productOrder":5,"hasBanner":false},
          {"productCode":"P11","productOrder":5,"hasBanner":false},
          {"productCode":"P23","productOrder":5,"hasBanner":false},
        ];
      } else {
        return this.$store.state.activeProducts;
      }
    },
    combinedActiveProducts() {
      let combinedActiveProducts = [];

      if (this.activeProducts) {
        this.activeProducts.forEach((element) => {
          let product = new Object();

          // Add main product
          try {
            product = require(`@/assets/json/${element.productCode}.json`);
            combinedActiveProducts.push(this.buildProduct(element, product));
          } catch {
            // Nothing
          }

          // Add sub products
          const subProducts = ["adult", "child"];

          for (const subProduct of subProducts) {
            try {
              product = require(`@/assets/json/${element.productCode}_${subProduct}.json`);
              combinedActiveProducts.push(this.buildProduct(element, product));
            } catch {
              // Nothing
            }
          }
        });
      }

      // Sort by product order
      combinedActiveProducts.sort((a, b) =>
        a.productOrder > b.productOrder
          ? 1
          : b.productOrder > a.productOrder
          ? -1
          : 0
      );

      return combinedActiveProducts;
    },
  },
  methods: {
    buildProduct(activeProduct, product) {
      const base = this.copy(activeProduct);

      return {
        ...base,
        productName: product.productName,
        productTitle: product.productTitle,
        productIntroText: product.productIntroText,
        productIcon: product.productIcon,
        productLink: product.productLink,
        productPath: product.productPath,
      };
    },
    /**
     * Log and error never outputs in production
     */
    clog(...args) {
      if (
        process.env.NODE_ENV == "development" ||
        process.env.NODE_ENV == "test" ||
        process.env.NODE_ENV == "beta"
      ) {
        console.log(...args);
      }
    },
    cerror(...args) {
      if (
        process.env.NODE_ENV == "development" ||
        process.env.NODE_ENV == "test" ||
        process.env.NODE_ENV == "beta"
      ) {
        console.error(...args);
      }
    },
    /**
     * Method for encrypting data for before sending to backend
     */
    encrypt(value) {
      var key = this.CryptoJS.enc.Utf8.parse("jR2gxvBq7JMYXZen");
      var iv = this.CryptoJS.enc.Utf8.parse("Qc8mbppPJ99GEsDU");
      var encrypted = this.CryptoJS.AES.encrypt(
        this.CryptoJS.enc.Utf8.parse(value),
        key,
        {
          keySize: 128 / 8,
          iv: iv,
          mode: this.CryptoJS.mode.CBC,
          padding: this.CryptoJS.pad.Pkcs7,
        }
      );
      return encrypted.toString();
    },
    /**
     * Format numbers into money
     */
    copy(value) {
      return JSON.parse(JSON.stringify(value));
    },
    /**
     * Format numbers into money
     */
    numFormat: numFormat,
    /*
     * Function for replacing values in string
     */
    parseBraces(string, arr) {
      // Try matching
      let matches = string.match(/{([^}]*)}/g);

      // Continue if matches found
      if (matches !== null && matches.length >= 1) {
        for (let i = 0; i < matches.length; i++) {
          let tmp = matches[i].slice(1, -1);
          if (tmp in arr) {
            let r = new RegExp("{" + tmp + "}", "g");
            string = string.replace(
              r,
              arr[tmp].value ? arr[tmp].value : "ukendt"
            );
          }
        }
      }
      // Replaced, or not return.
      return string;
    },
    /**
     * Capitalize first letter of sentence
     */
    capitalizeFirstLetter(string) {
      return string.charAt(0).toUpperCase() + string.slice(1);
    },
    /*
     * Scroll to packages when choosing coverage
     */
    scrollToTop() {
      //let body = document.getElementById("app");
      //body.scrollIntoView();
      window.scrollTo(0, 0);
    },
    /*
     * Scroll to certain element
     */
    scrollTo(id) {
      let element = document.getElementById(id);
      let offset = element.offsetTop - 40;

      window.scrollTo(0, offset);
    },
    /*
     * Get first name
     */
    getFirstName(fullName) {
      // Variable to return with first name
      let firstName = "";

      // Split names in spaces
      let namesArray = fullName.split(" ");

      namesArray.forEach((element, index) => {
        if (index != namesArray.length - 1) {
          firstName += element + " ";
        }
      });

      return firstName;
    },
    /*
     * Get first name
     */
    getLastName(fullName) {
      // Split names in spaces
      let namesArray = fullName.split(" ");

      // Return last index in array
      return namesArray.pop();
    },
    /*
     * Get product description
     */
    highlightText(search, result) {
      if (!search || !result) {
        return result;
      }
      return result.replace(new RegExp(search, "gi"), (match) => {
        return `<strong>${match}</strong>`;
      });
    },
    /*
     * Get product description
     */
    getProductDescription(products) {
      // If product is empty, return null
      if (!products || products == 0) return null;

      // Begin string to return
      let productDescription = "";
      // Loop through all products
      products.forEach((product) => {
        // Add product name
        productDescription += `${product.productName}:\n`;

        // Run through fields and fill out data
        // eslint-disable-next-line no-unused-vars
        for (const [key, value] of Object.entries(product.fields)) {
          // Only send if the field is filled out
          if ((value.type != "Hidden" || value.forceDesc || value.henvendelse) && value.value != null && typeof value.value != "object") {
            if (typeof value.value == "boolean") {
              productDescription += `${value.shortLabel ? value.shortLabel : value.label}: ${value.value ? 'Ja' : 'Nej'}\n`;
            } else if (value.type == "Select" && value.selected) {
              productDescription += `${value.shortLabel ? value.shortLabel : value.label}: ${value.selected.value}\n`;
            } else if (value.type == 'radio2') {
              productDescription += `${value.shortLabel ? value.shortLabel : value.label}: ${value.options.find((val) => { return val.value == value.value; }).headline}\n`;
            } else if (value.type == 'date') {
              productDescription += `${value.shortLabel ? value.shortLabel : value.label}: ${dayjs(value.value).format('DD-MM-YYYY')}\n`;
            } else if (value.type != "Select") {
              productDescription += `${value.shortLabel ? value.shortLabel : value.label}: ${value.value}\n`;
            }
          }
        }
        // If package is chosen, send with
        if (product.package) {
          productDescription += `Valgt pakke: ${product.package.name}${product.package.packageCRMHelpMessage}\n`;
        }

        // Loop through extra coverages, and add if chosen
        if (product.coverages) {
          // eslint-disable-next-line no-unused-vars
          for (const [key, value] of Object.entries(product.coverages)) {
            if (value.chosen) {
              productDescription += `Ekstra dækning: ${value.value}\n`;
            }
          }
        }

        // If price is available, show here
        if (product.price && product.price.totalMonthlyPrice) {
          productDescription += `Pris: ${product.price.totalMonthlyPrice}\n`;
        }

        // Add end to product
        productDescription += `\n`;

        // Add extra insurances
        if ("extra" in product) {
          for (const key in product.extra) {
            const extra = product.extra[key];

            productDescription += `${extra.value}\n`;
            productDescription += `Pris: ${product.extraPrices[key].totalMonthlyPrice}\n`;

            productDescription += `\n`;
          }
        }
      });

      this.clog(productDescription);

      return productDescription;
    },
    /*
     * Function for getting product name
     */
    getProductName(product) {
      // Add the possibility to have static names
      if (!Array.isArray(product.basketName)) return product.basketName;

      let productName = (product.productTitle || product.productName) + " (";

      product.basketName.forEach((element, index) => {
        // Add deep value of element
        productName += this.getDeepValue(product, element);

        // Add space if it is not the last item
        if (index != product.basketName.length - 1) {
          productName += " ";
        }
      });

      productName += ")";

      return productName;
    },
    /*
     * Function for getting deep nested info
     */
    getDeepValue(object, path) {
      let pathArray = path.split(".");

      for (var i = 0; i < pathArray.length; i++) {
        // Name error
        if (object !== null && !(pathArray[i] in object)) {
          return "ERROR";
        }

        // Null value error
        if (object === null) {
          return "ERROR";
        }

        object = object[pathArray[i]];
      };

      return object;
    },
    /*
     * Function from form elements emitted from component
     */
    dateFormat(value) {
      if (!value) return "";
      return dayjs(value).format("YYYY-MM-DD");
    },
    /*
     * Function from form elements emitted from component
     */
    formFunction(functionName, parameter) {
      this[functionName](parameter);
    },
    /*
     * Function for checking whether the CPR number is valid
     */
    checkModulus11(cpr) {
      if (cpr.match(/[0-9]{10}/)) {
        cpr = cpr.replace(/-/g, "");
        var chk = 0;
        for (var i = 9; i > -1; i--) {
          chk += +cpr.charAt(i) * (i > 2 ? 10 - i : 4 - i);
        }
        if (chk % 11 == 0) return true;
      }
      return false;
    },
    /*
     * Set data from api into fields
     */
    setFromData(data, product, renameMap = {}) {
      // Loop data form api
      for (const [dataKey, dataValue] of Object.entries(data)) {
        // loop data in product and match
        for (const [productKey, productValue] of Object.entries(
          product.fields
        )) {
          if (dataKey == productKey) {
            productValue.value = dataValue;
          }
        }
      }

      // Map renamed fields
      for (const [write, read] of Object.entries(renameMap)) {
        if (write in product.fields && read in data) {
          product.fields[write].value = data[read];
        }
      };
    },
    /*
     * Show modal
     */
    showModal(title, description) {
      let modal = {
        title: this.$options.filters.expand(title),
        description: this.$options.filters.expand(description),
      };
      this.$store.commit("setModal", modal);
    },
    /*
     * Function for tracking data
     */
    trackData(event, product, basket, validation) {
      let self = this;

      let trackingObject = {
        event: event,
        currentProduct: product ? new Object() : undefined,
        basket: basket.length ? new Object() : undefined,
        validationMessage: validation ? null : undefined,
      };

      // Set current products
      if (product) {
        // eslint-disable-next-line no-unused-vars
        for (const [key, value] of Object.entries(product.fields)) {
          if (value.analytics) {
            if (value.selected && value.selected.value) {
              trackingObject.currentProduct[value.property] = value.selected.value;
            } else {
              trackingObject.currentProduct[value.property] = value.value;
            }
          }
        }
        if (product.package) {
          trackingObject.currentProduct.package = product.package.name;
          if (product.package.price) {
            trackingObject.currentProduct.price = product.package.price.totalMonthlyPrice;
          }
        }
      }

      // Set basket products
      if (basket.length) {
        // Set number of products
        trackingObject.basket.numberOfProducts = basket.length;

        // Set totalPrice
        trackingObject.basket.totalPrice = 0;

        let basketProducts = new Array();

        // Run throught elements
        basket.forEach((element) => {
          // Add productId to array
          if (element.productId) {
            basketProducts.push(element.productId);
          }

          // Add up total price
          if (element.price && element.price.totalMonthlyPrice) {
            trackingObject.basket.totalPrice += element.price.totalMonthlyPrice;
          }
        });

        // Sort array of products
        basketProducts.sort();

        // Insert product combination as string
        trackingObject.basket.products = basketProducts.toString();
      }

      // If validation error is shown
      if (validation) {
        trackingObject.validationMessage = validation;
      }

      self.$gtm.trackEvent(trackingObject);
    },
  },
});

Vue.filter("dateFormat", function (value) {
  if (!value) return "";
  return dayjs(value).format("DD-MM-YYYY");
});

Vue.filter("highlight", function (word, query) {
  var check = new RegExp(query, "ig");
  // eslint-disable-next-line no-unused-vars
  return word.toString().replace(check, function(matchedText,a,b){
    return `<strong>${matchedText}</strong>`;
  });
});

Vue.filter("cprstrToCpr", (val) => {
  return val.substr(0, 2) + val.substr(3, 2) + val.substr(8, 2) + val.substr(11, 4);
});

Vue.filter("cprstrToAge", (val) => {
  const d = val.substr(0, 2);
  const m = val.substr(3, 2);
  const y = val.substr(6, 4);

  const bd = dayjs(`${y}-${m}-${d}`).toDate();
  const td = new Date();

  let age = td.getFullYear() - bd.getFullYear();
  const mb = td.getMonth() - bd.getMonth();

  if (mb < 0 || (mb === 0 && td.getDate() < bd.getDate())) {
    age--;
  }

  return age;
});

Vue.filter("cprToAge", (val) => {
  if (!val) return 0;

  const td = new Date();

  const d = val.substr(0, 2);
  const m = val.substr(2, 2);
  const y = val.substr(4, 2);
  const fy = y <= parseInt(td.getFullYear().toString().substr(-2)) ? `20${y}` : `19${y}`;

  const bd = dayjs(`${fy}-${m}-${d}`).toDate();

  let age = td.getFullYear() - bd.getFullYear();
  const mb = td.getMonth() - bd.getMonth();

  if (mb < 0 || (mb === 0 && td.getDate() < bd.getDate())) {
    age--;
  }

  return age;
});

Vue.filter("dateToAge", (val) => {
  if (!val) return 0;

  const td = new Date();

  const d = parseInt(val.substr(8, 2));
  const m = parseInt(val.substr(5, 2));
  const fy = parseInt(val.substr(0, 4));

  const bd = dayjs(`${fy}-${m}-${d}`).toDate();

  let age = td.getFullYear() - bd.getFullYear();
  const mb = td.getMonth() - bd.getMonth();

  if (mb < 0 || (mb === 0 && td.getDate() < bd.getDate())) {
    age--;
  }

  return age;
});

Vue.filter("integer", (val) => {
  return parseInt(val);
});

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
